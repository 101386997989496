import axios from "axios";

class SessionDetailsService {
  async getDetails () {
    return await axios
      .get("api/sessionDetails")
      .then(r => r.data)
      .catch(e => console.log(e));
  }
}

export default new SessionDetailsService();
