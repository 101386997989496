
import { defineComponent, onMounted, computed, ref } from "vue";
import SessionDetailsService from "../services/sessionDetailsService";
import { Details } from "../models/sessionDetails/sessionDetails";
import SessionModule from "../store/modules/SessionDetails/sessionDetails";

export default defineComponent({
  setup () {
    const loading = ref(true);

    const loadDetails = async () => {
      return await SessionDetailsService.getDetails().then(res => {
        SessionModule.setDetails(res as Details);
      });
    };

    const details = computed((): Details => {
      return SessionModule.getDetails;
    });

    const userAgent = computed(() => {
      return navigator.userAgent;
    });

    const initializePage = async () => {
      await loadDetails();
      loading.value = false;
    };

    onMounted(() => {
      initializePage();
    });

    return { loading, details, userAgent };
  },
});
