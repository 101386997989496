/* eslint-disable */
import Vue from "vue";
import Vuex from "vuex";
import { Action, getModule, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { Details } from "../../../models/sessionDetails/sessionDetails";
import store from "@/store/index";
Vue.use(Vuex);

@Module({ name: "SessionDetails", namespaced: true, dynamic: true, store })
class SessionDetails extends VuexModule {
  sessionDetails = {} as Details;

  get getDetails () {
    return this.sessionDetails;
  }

  @Mutation
  SET_DETAILS (details: Details) {
    this.sessionDetails = details;
  }

  @Action
  setDetails (details: Details) {
    this.SET_DETAILS(details);
  }
}

export default getModule(SessionDetails);
